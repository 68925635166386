import { styled } from "@linaria/react";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const S = {
  Container,
  Header
};

export default S;
