import React from "react";

import Pill from "components/atoms/Pill";
import { X as XIcon } from "react-feather";

import useEntityList from "util/hooks/useEntityList";
import S from "./styles";

const EntityListControls = () => {
  const {
    state: { filters, activeFilters, details },
    updateActiveFilter
  } = useEntityList();

  return (
    <S.Container>
      <div>{details?.title}</div>
      <S.Controls>
        Filter by:{" "}
        {filters?.map(({ label, count, filterBy }) => {
          const isActive = activeFilters?.includes(filterBy);
          return (
            <Pill
              key={`EntityListControl-${label}`}
              isDisabled={count === 0}
              rightAddon={isActive ? <XIcon /> : count}
              isActive={isActive}
              onClick={() => updateActiveFilter(filterBy)}
            >
              {label}
            </Pill>
          );
        })}
      </S.Controls>
    </S.Container>
  );
};

export default EntityListControls;
