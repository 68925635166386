import React from "react";

import EntityListToggle from "components/atoms/EntityListToggle";
import EntityCollection, {
  EntityCollectionDisplayStyle
} from "components/molecules/EntityCollection";
import EntityCard from "components/molecules/EntityCard";
import EntityCardExtended from "components/molecules/EntityCardExtended";
import useEntityList from "util/hooks/useEntityList";

import S from "./styles";

const EntityList = () => {
  const {
    state: { isExpanded, details },
    filteredEntityList,
    filteredCommissionedEntityList,
    commissionReport
  } = useEntityList();

  return (
    <>
      <S.Container isExpanded={isExpanded}>
        <EntityCollection
          title={details?.entityListTitle}
          entities={filteredEntityList}
          displayStyle={EntityCollectionDisplayStyle.grid}
          onNewReport={commissionReport}
          CardComponent={EntityCard}
        />
      </S.Container>
      <EntityListToggle />
      <S.Divider />
      <EntityCollection
        title={details?.commissionedEntityListTitle}
        entities={filteredCommissionedEntityList}
        displayStyle={EntityCollectionDisplayStyle.list}
        CardComponent={EntityCardExtended}
        emptyEntitiesText="Your related parties research will appear here"
      />
    </>
  );
};

export default EntityList;
