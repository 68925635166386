import React from "react";
import EntityListDetails from "components/atoms/EntityListDetails";
import EntityListControls from "components/molecules/EntityListControls";

import S from "./styles";

const EntityListHeader = () => {
  return (
    <S.Container>
      <S.Header>
        <EntityListControls />
        <EntityListDetails />
      </S.Header>
    </S.Container>
  );
};

export default EntityListHeader;
