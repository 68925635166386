import React, { type FC } from "react";

import { standardColors } from "styles/colors";
import { type EntityRiskFlags, EntityType } from "api/entities";
import Popover from "components/atoms/Popover";

import S from "./styles";

interface Props {
  riskFlags?: EntityRiskFlags;
  entityType: EntityType;
}

const riskIconsByEntityType = {
  [EntityType.organisation]: [
    {
      flag: "sanctions",
      icon: <S.SanctionsIcon />,
      tooltip: "We have identified the subject as sanctioned"
    },
    {
      flag: "watchlists",
      icon: <S.WatchlistsIcon />,
      tooltip: "We have identified the subject on a watchlist"
    },
    {
      flag: "financial_crimes",
      icon: <S.FinancialCrimesIcon />,
      tooltip: "Financial crime flagged for review"
    },
    {
      flag: "proceedings",
      icon: <S.ProceedingsIcon />,
      tooltip: "Legal proceedings flagged for review"
    }
  ],
  [EntityType.person]: [
    {
      flag: "sanctions",
      icon: <S.SanctionsIcon />,
      tooltip: "We have identified the subject as sanctioned"
    },
    {
      flag: "watchlists",
      icon: <S.WatchlistsIcon />,
      tooltip: "We have identified the subject on a watchlist"
    },
    {
      flag: "peps",
      icon: <S.PepsIcon />,
      tooltip:
        "We have identified the subject as a Politically Exposed Person (PEP)"
    },
    {
      flag: "financial_crimes",
      icon: <S.FinancialCrimesIcon />,
      tooltip: "Financial crime flagged for review"
    },
    {
      flag: "proceedings",
      icon: <S.ProceedingsIcon />,
      tooltip: "Legal proceedings flagged for review"
    }
  ]
};

const EntityRiskIcons: FC<Props> = ({ entityType, riskFlags }) => {
  const riskIcons = riskIconsByEntityType[entityType];
  return (
    <S.RiskIcons>
      {riskIcons.map(({ flag, icon, tooltip }) => {
        const isActive = !!(riskFlags && flag in riskFlags);

        return (
          <Popover
            key={flag}
            disabled={!isActive}
            content={<S.Tooltip>{tooltip}</S.Tooltip>}
            hideArrow
            arrowColor={standardColors.white}
          >
            <S.RiskIcon isActive={isActive}>{icon}</S.RiskIcon>
          </Popover>
        );
      })}
    </S.RiskIcons>
  );
};

export default EntityRiskIcons;
